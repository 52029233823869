@import "./assets/styles/colors.scss";

body {
  font-family: "Hind", sans-serif;
  background-color: $color-dark;
}

li,
span,
p {
  color: $color-light;
  font-size: 1.1em;
}

h1 {
  font-weight: 700;
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 1.2em;
}

h2,
h3,
h4 {
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5 {
  color: $color-secondary;
}

h5 {
  font-weight: 300;
}

a {
  color: $color-accent;
}

.nav {
  display: flex;
  align-items: flex-end;
  margin: 0px 10% 0px 10%;
  padding: 16px 16px;
  column-gap: 24px;
  height: 60px;
  color: $color-secondary;
}

.tag {
  column-gap: 24px;
  display: flex;
  justify-content: flex-end;
}

.date {
  display: flex;
  column-gap: 8px;
  justify-content: flex-end;
}

.tag > div > span {
  background-color: $color-accent;
  color: $color-primary;
  font-size: 14px;
  border-radius: 16px;
  padding: 4px 8px;
  color: #2c3d55;
  font-size: 14px;
}

.cover > img {
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 8rem 8rem 0 0;
  // border-radius: 16px 16px 0px 0px;
}

.content {
  margin: 0px 10% 0px 10%;
  padding: 16px 16px;
}

.post {
  overflow-wrap: anywhere;
  margin: 32px 10% 24px 10%;
  background-color: $color-primary;
  //border-radius: 16px 16px 16px 16px;
  border-radius: 8rem 8rem 0 0;
  -webkit-box-shadow: 10px 10px 32px 16px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 10px 10px 32px 16px rgba(0, 0, 0, 0.11);
  box-shadow: 10px 10px 32px 16px rgba(0, 0, 0, 0.11);
}

footer {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  text-align: center;
}
